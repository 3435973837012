// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--447f2";
export var animateScrollOut = "styles-module--animateScrollOut--95590";
export var colorSequence = "styles-module--colorSequence--c43ab";
export var descriptions = "styles-module--descriptions--6afa0";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--c105d";
export var grow = "styles-module--grow--fecb7";
export var growAndShrink = "styles-module--growAndShrink--f6f3e";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--76010";
export var headings = "styles-module--headings--3524c";
export var moveBg = "styles-module--move-bg--1b92b";
export var primaryVideo = "styles-module--primaryVideo--18ff6";
export var rotateLoop = "styles-module--rotateLoop--18594";
export var spin = "styles-module--spin--d18a6";
export var spinCounter = "styles-module--spinCounter--66ece";
export var subVideos = "styles-module--subVideos--ae061";
export var videoBlocks = "styles-module--videoBlocks--0c08d";